<template>
  <router-view />
</template>

<script>
import jigouZim from "@/until/index.js";
import { ReduserenteruserOpenAps, ReduserenterUserCloseAps } from "@/axios/api";
import { computed, reactive, onMounted, onBeforeUnmount , onDeactivated} from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    onMounted(() => {
      jigouZim.loginZim();
    });
    const handleResize = ()=>{
      if(window.innerWidth < 800){
        window.location.href = 'https://www.mengmatv.com/h5';
      }
    }

    
    onMounted(()=>{
      handleResize()
    })
  },
  
};


</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
