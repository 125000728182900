import { ZIM } from "zego-zim-web";
import { generateToken } from './config.js';
import { appConfig } from './config.js';
const zim = ZIM.create(appConfig);
import store from "@/store"
export { zim };

export default {
    // 登录即购
    loginZim(userID, userName, type) {
        //判断是否是登录

        if (store.state.vuexIslogin == 1) {

            let loginForm = {
                userID: store.state.vuserid,
                userName: store.state.verName,
            };
            const token = generateToken(loginForm.userID);
                zim.login(loginForm, token)
                .then((res) => {
                    // 登录成功
                    console.log("登录成功");                 
                    // return true;
                })
                .catch((err) => {
                    // 登录失败
                    console.log("登录失败");
                    console.log(err)
                    // return false;
                });
          
        } else {

            let loginForm = {}
            if (!store.state.vuserid) {
                // 生成一个8位的随机整数
                const randomNumber = Math.floor(Math.random() * 100000000);
                // 将随机整数限制在8位数范围内，并转换为字符串
                const eightDigitNumber = String(randomNumber).substring(0, 8);
                loginForm = {
                    userID: 'mm' + eightDigitNumber,
                    userName: '游客' + eightDigitNumber,
                };
            } else {
                loginForm.userID = store.state.vuserid
                loginForm.userName = store.state.verName
            }

            let zimToken = generateToken(loginForm.userID);

            zim.login(loginForm, zimToken)
                .then(() => {
                    // 登录成功
                    console.log("游客登录成功");
                    store.commit('addVuservuex', [loginForm.userID, loginForm.userName])
                })
                .catch((err) => {
                    // 登录失败
                    console.log("游客登录失败", loginForm);
                    console.log(err);
                });
        }
    },

    // 退出登录
    jiglogout() {
        zim.logout()
        console.log('退出登录')
    },

    // 创建房间
    enterRoom(roomInfo, { }) {
        if (!roomInfo.roomID) return
        console.log('创建房间', roomInfo)
     
        return zim.enterRoom(roomInfo, {})
            .then((res) => {
                // 创建房间成功
                console.log("创建房间成功");
                return true;
            })
            .catch((err) => {
                // 创建房间失败
                console.log("创建房间失败", err);
                console.log('创建房间err', roomInfo)

                return false;
            });

    },

    // 离开房间
    leaveRoom(roomId) {

        zim.leaveRoom(roomId)
            .then((res) => {
                // 离开房间成功
                console.log("离开房间成功");
                return true;
            })
            .catch((err) => {
                // 离开房间失败
                console.log("离开房间失败", err);
                return false;
            });
    },

    // 发送消息
    sendMes(roomId, message, userName,userLevel,color) {
        var toConversationID = roomId; // 对方 userID
        var conversationType = 1; // 会话类型，取值为 单聊：0，房间：1，群组：2
        var config = {
            priority: 2, // 设置消息优先级，取值为 低：1（默认），中：2，高：3
        };
        var notification = {
            onMessageAttached: function (message) { }
        }
        // 附加消息
        let exTendDate = JSON.stringify(
            {
                Anchor: false,
                userName: userName,
                userLevel,
                color,
            }
        );
        var messageTextObj = { type: 1, message: message,extendedData:exTendDate};
       return zim.sendMessage(messageTextObj, toConversationID, conversationType, config, notification)
            .then(({ message }) => {
                // 发送成功
                console.log("发送成功");
                return true;
            })
            .catch((err) => {
                // 发送失败
                console.log("发送失败", err);
                return false;
            });
    },
}