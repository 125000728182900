<template>
  <div class="footer">
    <div class="footerImg">
      <img src="@/assets/images/icon_logo.png" alt="" />
    </div>
    <div class="footerText">
      <div @click="skipRouter('/course')">新手主播教程</div>
      <div @click="skipRouter('/commonProblem')">直播常见问题</div>
      <div @click="skipRouter('/agreement')">用户协议说明</div>
    </div>
    <div class="copytext">沪ICP备15052233号-1增值电信业务经营许可证：沪B2-20200279沪网文（2021）0747-045 号广播电视节目制作经营许可证：（沪）字第04175号 沪公网安备 31099202012378号</div>
    <div class="copytext" style="padding-top: 12px;">北京猛犸体育科技有限公司 Copyright © 2015-2024 MenM.ALL Rights Reserved </div>

  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    // 路由跳转
    const skipRouter = path => router.push(path);
    return {
      skipRouter,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  color: #fff;
  text-align: center;
  background: #0e0e18;
  padding: 10px 0;
  .footerImg {
    height: 60px;
    padding-top: 10 px;
    margin: 0 auto;
    img {
      height: 100%;
    }
  }
  .footerText {
    padding: 10px 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 14px;
    div:nth-child(2) {
      margin: 0 30px;
    }
  }
  .copytext {
    font-size: 12px;
  }
}
</style>