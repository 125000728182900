<template>
  <div class="index">
    <div is="vue:Header"></div>
    <div class="container"><router-view/></div>
    <div is="vue:Footer"></div>
  </div>
</template>

<script>
import { ref, onDeactivated, onMounted, onBeforeUnmount,reactive,computed} from "vue";
import { useStore } from "vuex"; 
import { ReduserenteruserOpenAps, ReduserenterUserCloseAps } from "@/axios/api";
import Header from "../header/header.vue";
import Footer from "../footer/footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      uniqueIdData: "", //设备唯一标识
      userInfo: computed(() => store.state.userInfomm), //用户信息
    });

    function getCookie(name) {
      let value = "; " + document.cookie;
      let parts = value.split("; " + name + "=");
      if (parts.length === 2) {
        return parts.pop().split(";").shift();
      }
      return null;
    }

    //设置或更新cookie
    function setCookie(name, value, maxAgeSeconds) {
      let cookie = name + "=" + value;
      if (maxAgeSeconds) {
        let expires = new Date();
        expires.setTime(expires.getTime() + maxAgeSeconds * 1000);
        cookie += "; expires=" + expires.toUTCString();
      }
      document.cookie = cookie;
    }
    function getNumber() {
      let uniqueId = getCookie("uniqueId");
      if (!uniqueId) {
        uniqueId =
          new Date().getTime().toString(36) +
          Math.random().toString(36).substring(2, 15);
          setCookie("uniqueId", uniqueId, 365 * 24 * 60 * 60); //将唯一标识符储存在cookie中，有效期为1年
      }
    }

    onMounted(async ()=>{
      // getNumber()
    })
    onBeforeUnmount(async () => {
    });
  },
};
</script>

<style lang="scss" scoped>
.index {
  .appVideo {
    position: fixed;
    right: 50%;
    bottom: 30px;
    background-color: #000;
    video {
      width: 400px;
      height: 200px;
    }
  }
  .container {
    width: 100%;
    min-height: calc(100vh - 186px);
    background-color: #f0f1f6;
  }
}
</style>