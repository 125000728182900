// 公告方法

function getBrowserId() {
  const cookieName = 'vue_browser_id';
  const cookieExpiresDays = 365; // 设置cookie有效期为一年
  const now = new Date();
  now.setTime(now.getTime() + cookieExpiresDays * 24 * 3600 * 1000); // 设置cookie过期时间

  // 检查cookie是否存在
  let browserId = getCookie(cookieName);
  if (!browserId) {
    browserId = generateUUID(); // 如果不存在，生成一个新的UUID
    setCookie(cookieName, browserId, now); // 并将其保存到cookie中
  }
  return browserId;
}
function generateUUID() {
  let d = new Date().getTime(); // 当前时间
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); // 高精度时间戳
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
  });
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
function setCookie(name, value, expires) {
  document.cookie = name + "=" + value + ";expires=" + expires.toUTCString();
}


export function exportGetBrowserId() {
  // 实现日期时间格式化逻辑
  return getBrowserId();
}