<template>
  <div
    :class="
      $route.name == 'home' || $route.name == 'liveInfo'
        ? 'headerOne'
        : 'headerTwo'
    "
  >
    <div class="headerTopFlex">
      <div class="headerCenter">
        <div
          class="imglogo"
          v-if="$route.name == 'home' || $route.name == 'liveInfo'"
        >
          <router-link to="/home">
            <img src="@/assets/images/logo.png" />
          </router-link>
        </div>
        <div class="imglogo" v-else>
          <router-link to="/home">
            <img src="@/assets/images/Group34773.png" />
          </router-link>
        </div>
        <div class="heraderNav">
          <div :class="$route.name == 'home' ? 'active' : ''" class="div">
            <a @click="spikRouter('/home')">首页</a>
          </div>
          <div :class="$route.name == 'schedule' ? 'active' : ''" class="div">
            <a @click="spikRouter('/schedule')">全部直播</a>
          </div>
          <div :class="$route.name == 'saicheng' ? 'active' : ''" class="div">
            <a @click="spikRouter('/saicheng')">赛程</a>
          </div>
          <div class="div"><a @click="spikRouter('/appinstall')">下载app</a></div>
        </div>
        <div class="videoLogoText" v-if="JSON.stringify(userInfoAds) != '{}'">
            <a :href="userInfoAds.ad_url || ''" :target="userInfoAds.ad_url? 'block':'_self'" class="onea" style="text-decoration: none;">
              <div class="ltText" v-if="userInfoAds.ad_text && !userInfoAds.isHasImgAd">
              <img :src="userInfoAds.ad_icon" alt="" v-if="userInfoAds.ad_icon">
              <span>{{userInfoAds.ad_text}}</span>
            </div>
            <div class="ltImg" v-if="userInfoAds.isHasImgAd && userInfoAds.ad_img">
              <img :src="userInfoAds.ad_img" alt="">
            </div>
            </a> 
          </div> 
      </div>
      <div class="headerRightOne" v-if="JSON.stringify(userInfo) == '{}'">
        <div @click="dialogVisibleLogin = true">登录</div>
        <div>|</div>
        <div @click="dialogVisibleRegister = true">注册</div>
      </div>
      <div class="headerRightTwo" v-if="JSON.stringify(userInfo) != '{}'">
        <!-- <div>预约</div> -->
        <div class="followBox">
          <div class="follow" @click="skipRouteruserinfo(1)">关注</div>

          <div class="followPosition">
            <div class="follwing" v-if="!userInfomm_gunzhu.length">
              <img src="@/assets/images2/none.png" alt="" class="none" />
              <span>预约列表空空如也~</span>
            </div>
            <div class="folAvatar" v-else>
              <div
                class="avabox"
                v-for="(item, index) in userInfomm_gunzhu"
                :key="index"
                @click="skipRouterLiveInfo(item.zb_id)"
              >
                <div class="aLeft">
                  <el-avatar :size="45" :src="item.headimg" />
                </div>
                <div class="avaright">
                  <p>{{ item.nickname }}</p>
                  <p>{{ item.info }}</p>
                </div>
              </div>
              <div class="iconMore" @click="skipRouteruserinfo(1)">
                <span>查看更多</span>
                <el-icon><ArrowRight /></el-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="avatar">
          <el-avatar
            :size="45"
            :src="userInfo.headimg"
            class="avatarCss"
            @click="skipRouteInfo"
          >
          </el-avatar>
          <div class="rightPosition">
            <div class="rtop">
              <p>
                <el-avatar :size="55" :src="userInfo.headimg" />
                <span>{{ userInfo.nickname }}</span>
                <!-- <span>{{ JSON.parse(localStorage.getItem('userInfomm')).nickname || 'user'}}</span> -->
              </p>
              <p @click="logout">退出登录</p>
            </div>
            <div class="avatarVip">
              <div class="aDengji">
                <span class="lvd" :style="{ backgroundColor: userInfo.vip.color }">Lv.{{ userInfo.vip.leve }}</span>
                <el-progress :percentage="userInfo.vip.num || 0" color="#ffbe32">
                  <span text style="font-size: 12px">{{ userInfo.vip.num }} / 100</span>
                </el-progress>
              </div>
              <div class="dbottom">
                <div class="dText">
                  <div class=da1>Lv.{{ userInfo.vip.leve }}</div>
                  <div class=da2>等级</div>
                </div>
                <div class="center"></div>
                <div class="dText">
                  <div class=da1>{{ userInfo.vip.num }}</div>
                  <div class=da2>经验</div>
                </div>
              </div>
            </div>
            <div class="avatarRow">
              <el-row>
                <el-col :span="6">
                  <div class="abox" @click="skipRouteruserinfo(0)">
                    <img src="@/assets/images2/gern.png" alt="">
                    <p>个人中心</p>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="abox" @click="skipRouteruserinfo(1)">
                    <img src="@/assets/images2/gern2.png" alt="">
                    <p>我的关注</p>
                  </div></el-col>
                <el-col :span="6"></el-col>
                <el-col :span="6"></el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 登录 -->
    <el-dialog
      v-model="dialogVisibleLogin"
      width="400px"
      @close="closeDialogOne('')"
    >
      <div class="dialogVisibleLogin">
        <div class="loginTitle">登录</div>

        <!-- <vue-tel-input v-model="phone" mode="international"></vue-tel-input> -->
        <el-form :model="form" :rules="rulesForm2" ref="ruleFormRef2">
          <div class="nameInput">
            <div class="name" @click="wayCodeListBoolean">{{ area }}</div>
            <div class="country-code-list" v-if="codeListBoolean">
              <ul>
                <div v-for="(item, index) in areaCode" :key="index">
                  <li class="country-code-title" v-if="item.iden">
                    <h4>{{ item.prefix }}</h4>
                  </li>
                  <li
                    class="country-code-item"
                    v-if="!item.iden"
                    @click="clickAreaCode(item.prefix)"
                  >
                    <span class="left">{{ item.cn }}</span
                    ><span class="right">{{ item.prefix }}</span>
                  </li>
                </div>
              </ul>
            </div>
            <el-form-item prop="phone">
              <el-input
                v-model="form.phone"
                :maxlength="11"
                placeholder="请输入您的手机号"
              />
            </el-form-item>
          </div>
          <div class="nameInput2">
            <el-form-item prop="password">
              <el-input
                v-model="form.password"
                placeholder="请输入6-16位字母或数字密码"
                show-password
              />
            </el-form-item>
          </div>
          <div class="checkboxText">
            <el-form-item>
              <el-checkbox v-model="checked1" size="small"
                >登录即代表你已同意<span
                  style="color: red"
                  @click="openAgreement"
                  >《用户协议服务》</span
                ></el-checkbox
              >
            </el-form-item>
            <span class="p" @click="routerPassword">忘记密码</span>
          </div>
          <div class="btnLogin">
            <div @click="wayswitch('注册')">注册新账号</div>
            <el-button
              type="danger"
              class="submit"
              :disabled="!checked1"
              @click="submitLogin"
              >登录</el-button
            >
          </div>
        </el-form>
        <img src="@/assets/images2/Rectangle.png" alt="" class="imgFindx" />
      </div>
    </el-dialog>

    <!-- 注册 -->
    <el-dialog
      v-model="dialogVisibleRegister"
      width="400px"
      @close="closeDialogOne('register')"
    >
      <div class="dialogVisibleLogin">
        <div class="loginTitle">注册</div>
        <el-form :model="formData" :rules="rulesForm" ref="ruleFormRef">
          <div class="nameInput">
            <div class="name" @click="wayCodeListBoolean">{{ area }}</div>
            <div class="country-code-list" v-if="codeListBoolean">
              <ul>
                <div v-for="(item, index) in areaCode" :key="index">
                  <li class="country-code-title" v-if="item.iden">
                    <h4>{{ item.prefix }}</h4>
                  </li>
                  <li
                    class="country-code-item"
                    v-if="!item.iden"
                    @click="clickAreaCode(item.prefix)"
                  >
                    <span class="left">{{ item.cn }}</span
                    ><span class="right">{{ item.prefix }}</span>
                  </li>
                </div>
              </ul>
            </div>
            <el-form-item prop="phone">
              <el-input
                v-model="formData.phone"
                :maxlength="11"
                placeholder="请输入您的手机号"
              />
            </el-form-item>
          </div>
          <div class="phoneCode">
            <el-form-item prop="sms_code">
              <el-input
                v-model="formData.sms_code"
                placeholder="获取并输入验证码"
              />
            </el-form-item>
            <div class="code">
              <span v-if="show" @click="waygetCode(formData.phone)"
                >获取验证码</span
              >
              <span v-else>{{ timeCount }}</span>
            </div>
          </div>
          <div class="userName">
            <el-form-item prop="nickname">
              <el-input
                v-model="formData.nickname"
                placeholder="请输入用户名"
              />
            </el-form-item>
          </div>
          <div class="userName">
            <el-form-item prop="password">
              <el-input
                v-model="formData.password"
                placeholder="请输入6-16位字母和数字密码"
                show-password
              />
            </el-form-item>
          </div>
          <div class="codeText">
            <el-form-item prop="sidentifyMode">
              <el-input
                v-model="formData.sidentifyMode"
                placeholder="请输入图片上的验证码"
              />
            </el-form-item>
            <div class="code" @click="refreshCode">
              <SIdentify :identifyCode="identifyCode"></SIdentify>
            </div>
          </div>
          <div class="checkboxText">
            <el-form-item>
              <el-checkbox v-model="checked1" size="small"
                >登录即代表你已同意<span
                  style="color: red"
                  @click="openAgreement"
                  >《用户协议服务》</span
                ></el-checkbox
              >
            </el-form-item>
            <span class="p" @click="routerPassword">忘记密码</span>
          </div>
          <div class="btnLogin">
            <div @click="wayswitch('登录')">登录账号</div>
            <el-button
              type="danger"
              class="submit"
              @click="register"
              :disabled="!checked1"
              >注册</el-button
            >
          </div>
        </el-form>
        <img src="@/assets/images2/Rectangle.png" alt="" class="imgFindx" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref,
  onMounted,
  onCreated,
  watch,
  computed,
  nextTick,
} from "vue";
import {
  GetSmsCode,
  GetCountryPhonePrefix,
  UserLoginUserEnroll,
  UserLoginUserLogin,
  loginRecord,
  getNowLiveAnchors,
  getVideoLogoMsg
} from "@/axios/api";
import { ElMessage } from "element-plus";
import SIdentify from "@/components/Sidentify.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import jigouZim from "@/until/index.js";
import { exportGetBrowserId } from "@/until/upmethods.js";

export default {
  components: {
    SIdentify,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    // const count = computed(() => store.state.count)
    // console.log('count',count)
    const state = reactive({
      count: computed(() => store.state.count),
      userInfomm_gunzhu: computed(() => store.state.userInfomm_gunzhu),
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      dialogVisibleLogin: false, //登录弹窗
      dialogVisibleRegister: false, //注册弹窗
      form: {
        //登录c
        phone: "",
        password: "",
      },
      formData: {
        //注册
        phone: "",
        password: "",
        nickname: "",
        sms_code: "",
        sidentifyMode: "", //图片验证码
      },
      checked1: false, //是否阅读协议
      timeCount: 60, //设置验证码60s初始值
      show: true,
      areaCode: [], //地区码
      area: "+86",
      codeListBoolean: false,
      userInfo: computed(() => store.state.userInfomm), //用户信息
      cookieID:'',
      userInfoAds:{}
    });
    const ruleFormRef = ref(); //注册表单的refs属性
    const ruleFormRef2 = ref(); //登录表单的refs属性

    //打开区域码弹窗
    const wayCodeListBoolean = () => {
      state.codeListBoolean = !state.codeListBoolean;
    };
    //选择地区
    const clickAreaCode = (v) => {
      state.codeListBoolean = false;
      state.area = v;
    };
    //注册Dialog关闭时回调
    const closeDialogOne = (e) => {
      if (e == "register") {
        ruleFormRef.value.resetFields();
      } else {
        ruleFormRef2.value.resetFields();
      }
      state.codeListBoolean = false;
      state.checked1 = false;
      state.area = "+86";
    };
    //获取短信验证码
    const wayGetSmsCode = (phone) => {
      GetSmsCode({
        phone,
      })
        .then((res) => {
          ElMessage({ type: "success", message: res.msg });
        })
        .catch((err) => {
          ElMessage({ type: "error", message: err.msg });
        });
    };
    //获取地区手机码
    const wayGetCountryPhonePrefix = () => {
      GetCountryPhonePrefix()
        .then((res) => {
          let data = res.data;
          const convertedData = [];

          for (const key in data) {
            convertedData.push({ prefix: key, cn: "", iden: true });
            data[key].forEach((item) => {
              item.iden = false;
              convertedData.push(item);
            });
          }
          state.areaCode = convertedData;
        })
        .catch((err) => {});
    };
    //跳转到用户详情
    const skipRouteInfo = () => {
      router.push("/userinfo");
    };
    // 切换区域 登录/注册
    const wayswitch = (e) => {
      if (e == "注册") {
        state.dialogVisibleLogin = false;
        state.dialogVisibleRegister = true;
      } else {
        state.dialogVisibleLogin = true;
        state.dialogVisibleRegister = false;
      }
    };
    // 注册区域---------
    // 设置按钮点击后禁用
    const dis = ref(false);
    const countDown = ref();
    const waygetCode = (ve) => {
      // 设置正则表达式————手机号判断
      let m =
        /^(?:(?:\+|00)86)?1(?:(?:3\d)|(?:4[5-9])|(?:5[0-35-9])|(?:6[2567])|(?:7[0-8])|(?:8\d)|(?:9[189]))\d{8}$/.test(
          state.formData.phone
        );
      if (!state.formData.phone) {
        setTimeout(() => {
          ElMessage({
            message: "手机号不能为空",
            type: "error",
          });
        }, 200);
      } else if (state.formData.phone.length !== 11) {
        setTimeout(() => {
          ElMessage.error("手机格式错误，请重新输入");
        }, 200);
      } else if (m) {
        wayGetSmsCode(ve);
        // setInterval()函数实现定时调用，1000为毫秒也就是1s
        countDown.value = setInterval(() => {
          setTimeout(() => {
            if (state.timeCount > 0 && state.timeCount <= 60) {
              dis.value = true;
              state.show = false;
              state.timeCount--;
            } else {
              state.show = true;
              // 取消setInterval()函数设定的定时执行操作
              clearInterval(state.timeCount);
            }
          }, 0);
        }, 1000);
      } else if (!m) {
        setTimeout(() => {
          ElMessage.error("手机格式错误，请重新输入");
        }, 200);
      }
    };
    //生成随机字符串
    function generateRandomString() {
      var result = "";
      var characters = "0123456789abcdefghijklmnopqrstuvwxyz";
      for (var i = 0; i < 4; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    }

    //设置图片验证码
    let identifyCode = ref(""); //图形验证码
    let identifyCodes = ref("1234567890abcdefjhijklinopqrsduvwxyz"); //验证码出现的数字和字母
    const randomNum = (min, max) => {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    };
    // 随机生成验证码字符串
    const makeCode = (o, l) => {
      for (let i = 0; i < l; i++) {
        identifyCode.value += o[randomNum(0, o.length)];
      }
    };
    // 更新图片验证码
    const refreshCode = () => {
      identifyCode.value = "";
      // makeCode(identifyCodes.value, 4);
      identifyCode.value = generateRandomString();
    };
    // 手机号码校验函数
    const checkPhoneNum = (rule, value, callback) => {
      var reg = /^1[3456789]\d{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("手机号码格式不正确"));
      }
    };
    //用户名校验
    const validateInput = (rule, value, callback) => {
      const regExp = /^[\u4e00-\u9fa5a-zA-Z0-9]{1,10}$/; // 正则表达式
      if (value && !regExp.test(value)) {
        // 校验失败
        callback(new Error("只能输入数字、字母、汉字，长度为1到10位"));
      } else {
        callback(); // 校验成功
      }
    };
    // 注册表单校验 -- 注册
    const rulesForm = reactive({
      // 手机号码校验规则
      phone: [
        { required: true, message: "请输入手机号码", trigger: "blur" },
        { validator: checkPhoneNum, trigger: "blur" },
      ],
      sms_code: [
        { required: true, message: "请输入数字验证码", trigger: "blur" },
        { pattern: /^\d{6}$/, message: "请输入六位数字", trigger: "blur" },
      ],
      nickname: [
        { required: true, message: "请输入用户名", trigger: "blur" },
        {
          validator: validateInput,
          message: "只能输入数字、字母、汉字，长度为1到10位",
          trigger: "blur",
        },
      ],
      password: [
        { required: true, message: "请输入数字密码", trigger: "blur" },
        // { pattern: /^\d{6}$/, message: "请输入六位数字密码", trigger: "blur" },
        {
          min: 6,
          max: 16,
          message: "密码长度应为6到16个字符",
          trigger: "blur",
        },
        {
          pattern: /^[0-9a-zA-Z]{6,16}$/,
          message: "密码应包含字母和数字,不含特殊字符",
          trigger: "blur",
        },
      ],
      sidentifyMode: [
        { required: true, message: "请输入图片上的验证码", trigger: "blur" },
        { min: 4, max: 4, message: "验证码长度为4位", trigger: "blur" },
      ],
    });
    // 表单校验 -- 登录
    const rulesForm2 = reactive({
      phone: [
        { required: true, message: "请输入手机号码", trigger: "blur" },
        { validator: checkPhoneNum, trigger: "blur" },
      ],
      password: [
        { required: true, message: "请输入数字密码", trigger: "blur" },
        // { pattern: /^\d{6}$/, message: "请输入六位数字密码", trigger: "blur" },
        {
          min: 6,
          max: 16,
          message: "密码长度应为6到16个字符",
          trigger: "blur",
        },
      ],
    });
    //跳转到用户协议
    const openAgreement = () => {
      // const routeUrl = "/agreement"; // 新窗口要跳转的路由路径
      // const newWindow = window.open("", "_blank");
      // if (newWindow) {
      //   newWindow.location.href = routeUrl;
      // }
      state.dialogVisibleLogin = false;
      state.dialogVisibleRegister = false;
      router.push("/agreement");
    };
    //注册 -校验
    const registerSubmit = async (formEl, v2) => {
      const valid = await formEl.validate((valid, fields) => {
        if (valid) {
          return true;
        } else {
          return false;
        }
      });
      return valid;
    };
    //请求注册登录接口
    const register = async () => {
      if (identifyCode.value != state.formData.sidentifyMode)
        return ElMessage({ type: "error", message: "图片验证码错误" });
      const bool = await registerSubmit(ruleFormRef.value, true);
      if (!bool) return;
      const params = {
        phone: state.formData.phone,
        sms_code: state.formData.sms_code,
        nickname: state.formData.nickname,
        password: state.formData.password,
      };
      //注册接口
      UserLoginUserEnroll(params)
        .then((res) => {
          ElMessage({ type: "success", message: res.msg });
          state.dialogVisibleRegister = false;
          state.dialogVisibleLogin = true;
        })
        .catch((err) => {
          ElMessage({ type: "error", message: err.msg });
        });
    };
    //登录
    const submitLogin = async (formEl) => {
      const bool = await registerSubmit(ruleFormRef2.value, false);
      if (!bool) return;
      let params = {
        phone: state.form.phone,
        password: state.form.password,
      };
      UserLoginUserLogin(params)
        .then((res) => {
          ElMessage({ type: "success", message: res.msg });
          window.localStorage.setItem("userInfomm", JSON.stringify(res.data));
          store.commit("addUserInfomm", res.data);
          store.commit("addVuservuex", [res.data.id, res.data.nickname]);
          state.dialogVisibleLogin = false;
          jigouZim.jiglogout();
          setTimeout(() => {
            jigouZim.loginZim();
          }, 1000);
          if (route.name == "liveInfo") {
            location.reload();
          }
        })
        .catch((err) => {
          ElMessage({ type: "error", message: err.msg });
        });
    };
    //退出登录
    const logout = () => {
      window.localStorage.removeItem("userInfomm");
      ElMessage({ type: "success", message: "账号已退出" });
      // state.userInfo = {}
      store.commit("removeUserInfomm");
      store.commit("addVuservuex", ["", ""]);
      jigouZim.jiglogout();
      setTimeout(() => {
        jigouZim.loginZim();
      }, 1000);
      location.reload();
    };
    //跳转到忘记密码
    const routerPassword = () => {
      router.push("/editpassword");
      state.dialogVisibleRegister = false;
      state.dialogVisibleLogin = false;
    };
    const spikRouter = (url) => {
      if (url == route.path) return;
      router.push(url);
      loginRecord({
          uid: store.state.userInfomm.id || '',
          cid:state.cookieID,
        }).then((res) => {
        }).catch(err=>{
        })
     
    };
    //获取关注 正在直播的主播
    const waygetNowLiveAnchors = () => {
      if (!store.state.userInfomm) return;
      getNowLiveAnchors({
        uid: store.state.userInfomm.id,
      })
        .then((res) => {
          store.commit("addGunzhuAnotor", { type: "add", data: res.data });
        })
        .catch((err) => {});
    };
    const skipRouteruserinfo = (type) => {
      router.push({
        path: "/userinfo",
        query: {
          type:type || 0,
        },
      });
    };
    const skipRouterLiveInfo = (id) => {
      router.push({
        path: "/liveInfo",
        query: {
          id,
          type: "live",
        },
      });
    };
    // 获取系统消息 
    const wayGetVideoLogoMsg = ()=>{
      getVideoLogoMsg()
      .then(res=>{
        console.log('res.data',res.data.ads.ads.length)
          if(res.data.ads.ads.length > 0){
            state.userInfoAds = res.data.ads.ads[0];
          }
       
      })
      .catch(err=>{})
    }
 
    onMounted(() => {
      identifyCode.value = "";
      makeCode(identifyCodes.value, 4);
      wayGetCountryPhonePrefix();
      waygetNowLiveAnchors();
      wayGetVideoLogoMsg();
      state.cookieID = exportGetBrowserId()
    });
    return {
      ...toRefs(state),
      spikRouter,
      refreshCode,
      routerPassword,
      makeCode,
      randomNum,
      waygetCode,
      wayswitch,
      skipRouteInfo,
      wayCodeListBoolean,
      clickAreaCode,
      closeDialogOne,
      openAgreement,
      registerSubmit,
      register,
      submitLogin,
      logout,
      skipRouteruserinfo,
      skipRouterLiveInfo,
      identifyCode,
      identifyCodes,
      rulesForm,
      rulesForm2,
      ruleFormRef2,
      ruleFormRef,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin ElementInputBotton {
  :deep(.el-input__wrapper) {
    padding: 1px 0px;
  }
  :deep(.el-form-item) {
    width: 100%;
    margin-bottom: 0;
  }
}
:deep(.el-form-item.is-error .el-input__wrapper) {
  box-shadow: none;
}
.headerOne {
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 99;
  background: #0b0b1a;
  .headerTopFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    padding: 10px 0;
    .headerRightTwo {
      display: flex;
      align-items: center;
      color: #fff;
      position: relative;
      .followBox {
        position: relative;

        &:hover .followPosition {
          display: block;
        }
        .followPosition {
          display: none;
          position: absolute;
          top: 23px;
          right: 0;
          color: #000;
          background: #fff;
          border-radius: 6px;
          overflow: hidden;
          transform: translateX(100px);
          box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
          z-index: 9;
          .folAvatar {
            width: 330px;
            box-sizing: border-box;
            .iconMore {
              width: 100%;
              text-align: center;
              padding: 6px 0;
              color: #333;
              font-size: 13px;
              background: #eff0f2;
              cursor: pointer;
            }
            .avabox {
              width: 330px;
              display: flex;
              align-items: center;
              padding: 10px 20px;
              .avaright {
                width: 251px;
                padding-left: 10px;
                cursor: pointer;
                p {
                  width: inherit;
                  font-size: 14px;
                  //超出一行省略号
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                p:nth-child(2) {
                  color: #999999;
                }
              }
            }
          }
          .follwing {
            width: 240px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #999;
            padding: 10px 0;

            box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);

            .none {
              width: 90px;
              height: 60px;
            }
          }
        }

        .follow {
          cursor: pointer;
          position: relative;
        }
        .follow::before {
          content: "";
          display: inline-block;
          width: 18px;
          height: 18px;
          background-image: url("@/assets/images2/guanzhu0.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
          position: absolute;
          left: -23px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .avatar {
        position: relative;
        // width: 100px;
        margin-left: 40px;
        &:hover .rightPosition {
          visibility: visible;
        }
        .avatarCss {
          float: right;
        }
        .rightPosition {
          visibility: hidden;
          position: absolute;
          right: -30px;
          top: 45px;
          width: 330px;
          background: #ffffff;
          box-shadow: 4px 4px 10px 0px rgba(181, 181, 181, 0.25);
          border-radius: 5px 5px 5px 5px;
          opacity: 1;
          z-index: 100;
          .avatarRow{
            padding: 10px 20px;
            .abox{
              cursor:pointer;
              color:#333333;
              font-size: 14px;
              text-align: center;
              img{
                width: 30px;
                height:30px;
              }
            }
          }
          .avatarVip {
            // width: 630px;
            width:100%;
            margin: 10px auto;
            padding-bottom:10px;
            border-bottom: 1px solid rgb(232, 232, 232);
            .dbottom {
              // width: 380px;  
              padding:0 20px;
              // margin: 20px auto 0;
              display: flex;
              align-items: center;
              justify-content: space-around;
              .dText {
                color:#333;
                text-align: center;
                .da1{
                  font-size: 14px;
                  font-weight: 600;
                }
                .da2{
                  font-size: 12px;
                  color: #777;
                }
              }
              .center {
                background-color: #ddd;
                height: 40px;
                width: 1px;
              }
            }
            .aDengji {
              padding:0 20px;
              display: flex;
              justify-content: center;
              .lvd {
                color: #fff;
                border-radius: 4px;
                font-size: 10px;
                margin-right: 10px;
                padding: 0 6px;
                height: 16px;
              }
              .el-progress--line {
                margin-bottom: 15px;
                width: 350px;
                height: 15px;
              }
            }
          }
          .rtop {
            padding: 20px 0 0px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p:nth-child(1) {
              display: flex;
              align-items: center;
              margin-left: 15px;
              font-size: 17px;
              span {
                max-width: 140px;
                margin-left: 10px;
                white-space: nowrap; //不换行
                overflow: hidden; //超出隐藏
                text-overflow: ellipsis;
              }
            }
            p:nth-child(2) {
              display: inline-block;
              border-radius: 2px;
              font-size: 14px;
              padding: 4px 10px;
              cursor: pointer;
              color: #828282;
              margin-right: 15px;
              border: 1px solid #bcbcbc;
            }
          }
        }
      }
      // div {
      //   position: relative;
      //   margin-left: 50px;
      //   cursor: pointer;
      // }
      // div:nth-child(2) {
      //   margin-right: 30px;
      // }
      // div:nth-child(1)::before {
      //   content: "";
      //   display: inline-block;
      //   width: 18px;
      //   height: 18px;
      //   background-image: url("@/assets/images2/yuyue0.png");
      //   background-repeat: no-repeat;
      //   background-position: center;
      //   background-size: 100%;
      //   position: absolute;
      //   left: -23px;
      //   top: 50%;
      //   transform: translateY(-50%);
      // }
    }
    .headerRightOne {
      display: flex;
      font-size: 16px;
      color: #fff;
      div {
        cursor: pointer;
        margin-left: 6px;
      }
      div:nth-child(1) {
        position: relative;
      }
      div:nth-child(1)::before {
        content: "";
        display: inline-block;
        width: 18px;
        height: 18px;
        background-image: url("@/assets/images2/denru0.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        position: absolute;
        left: -23px;
        top: 50%;
        transform: translateY(-50%);
      }
      div:nth-child(1):hover::before {
        background-image: url("@/assets/images2/denru2.png");
      }
      div:nth-child(2n-1):hover {
        color: #ff5756;
      }
    }
    .headerCenter {
      display: flex;
      align-items: center;
      .videoLogoText{
          
          .onea{
            display: block;
          }
          .ltImg{
           img{
            width:270px;
            height: 46px;
           }
          }
          .ltText{
            display: flex;
            aling-items: center;
            color:#ffffff;
            background:#d4141a;
            padding: 6px 10px;
            border-radius: 5px;
            cursor: pointer;
            img{
              width:20px;
              height:20px;
              margin-right:10px
              }
            
          }
        }
      .heraderNav {
        display: flex;
        align-items: center;
        color: #fff;
        .div {
          height: 36px;
          line-height: 36px;
          margin: 0 16px;
          text-align: center;
          cursor: pointer;
        }
        .div:not(:last-child):hover {
          color: #fff;
          border-radius: 5px;
          background-color: #fe4b4c;
        }
        .div:last-child {
          a {
            color: #ff5756;
            text-decoration: none;
          }
          &:hover::after {
            content: "";
            display: block;
            width: 100%;
            height: 100px;
            background-image: url("https://www.xm888.app/Public/icon/ios.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            margin-top: 10px;
          }
        }
        a {
          padding: 0 18px;
        }
        .active {
          color: #fff;
          border-radius: 5px;
          background-color: #fe4b4c;
        }
      }
      a {
        color: #fff;
        text-decoration: none;
      }

      .imglogo {
        width: 160px;
        height: 40px;
        margin-right: 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.headerTwo {
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #fff;
  .headerTopFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    padding: 10px 0;
    .headerRightTwo {
      display: flex;
      align-items: center;
      color: #000;
      .followBox {
        position: relative;

        &:hover .followPosition {
          display: block;
        }
        .followPosition {
          display: none;
          position: absolute;
          top: 23px;
          right: 0;
          background: #fff;
          border-radius: 6px;
          overflow: hidden;
          transform: translateX(100px);
          box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
          z-index: 9;
          .folAvatar {
            width: 330px;
            box-sizing: border-box;
            .iconMore {
              width: 100%;
              text-align: center;
              padding: 6px 0;
              color: #333;
              font-size: 13px;
              background: #eff0f2;
              cursor: pointer;
            }
            .avabox {
              width: 330px;
              display: flex;
              align-items: center;
              padding: 10px 20px;
              .avaright {
                width: 251px;
                padding-left: 10px;
                cursor: pointer;
                p {
                  width: inherit;
                  font-size: 14px;
                  //超出一行省略号
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                p:nth-child(2) {
                  color: #999999;
                }
              }
            }
          }
          .follwing {
            width: 240px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #999;
            padding: 10px 0;

            box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);

            .none {
              width: 90px;
              height: 60px;
            }
          }
        }

        .follow {
          cursor: pointer;
          position: relative;
        }
        .follow::before {
          content: "";
          display: inline-block;
          width: 18px;
          height: 18px;
          background-image: url("@/assets/images2/guanzhu1.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
          position: absolute;
          left: -23px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .avatar {
        position: relative;
        width: 100px;
        &:hover .rightPosition {
          visibility: visible;
        }
        .avatarCss {
          float: right;
        }
        .rightPosition {
          visibility: hidden;
          position: absolute;
          right: -30px;
          top: 45px;
          width: 330px;
          background: #ffffff;
          box-shadow: 4px 4px 10px 0px rgba(181, 181, 181, 0.25);
          border-radius: 5px 5px 5px 5px;
          opacity: 1;
          z-index: 100;
          .avatarRow{
            padding: 10px 20px;
            .abox{
              cursor:pointer;
              color:#333333;
              font-size: 14px;
              text-align: center;
              img{
                width: 30px;
                height:30px;
              }
            }
          }
          .avatarVip {
            // width: 630px;
            width:100%;
            margin: 10px auto;
            padding-bottom:10px;
            border-bottom: 1px solid rgb(232, 232, 232);
            .dbottom {
              // width: 380px;  
              padding:0 20px;
              // margin: 20px auto 0;
              display: flex;
              align-items: center;
              justify-content: space-around;
              .dText {
                color:#333;
                text-align: center;
                .da1{
                  font-size: 14px;
                  font-weight: 600;
                }
                .da2{
                  font-size: 12px;
                  color: #777;
                }
              }
              .center {
                background-color: #ddd;
                height: 40px;
                width: 1px;
              }
            }
            .aDengji {
              padding:0 20px;
              display: flex;
              justify-content: center;
              .lvd {
                color: #fff;
                border-radius: 4px;
                font-size: 10px;
                margin-right: 10px;
                padding: 0 6px;
                height: 16px;
              }
              .el-progress--line {
                margin-bottom: 15px;
                width: 350px;
                height: 15px;
              }
            }
          }
          .rtop {
            padding: 20px 0 0px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p:nth-child(1) {
              display: flex;
              align-items: center;
              margin-left: 15px;
              font-size: 17px;
              span {
                max-width: 140px;
                margin-left: 10px;
                white-space: nowrap; //不换行
                overflow: hidden; //超出隐藏
                text-overflow: ellipsis;
              }
            }
            p:nth-child(2) {
              display: inline-block;
              border-radius: 2px;
              font-size: 14px;
              padding: 4px 10px;
              cursor: pointer;
              color: #828282;
              margin-right: 15px;
              border: 1px solid #bcbcbc;
            }
          }
        }
      }

      // div {
      //   position: relative;
      //   margin-left: 50px;
      //   cursor: pointer;
      // }
      // div:nth-child(2) {
      //   margin-right: 30px;
      // }
      // div:nth-child(1)::before {
      //   content: "";
      //   display: inline-block;
      //   width: 18px;
      //   height: 18px;
      //   background-image: url("@/assets/images2/yuyue1.png");
      //   background-repeat: no-repeat;
      //   background-position: center;
      //   background-size: 100%;
      //   position: absolute;
      //   left: -23px;
      //   top: 50%;
      //   transform: translateY(-50%);
      // }
    }
    .headerRightOne {
      display: flex;
      font-size: 16px;
      color: #000;
      div {
        cursor: pointer;
        margin-left: 6px;
      }
      div:nth-child(1) {
        position: relative;
      }
      div:nth-child(1)::before {
        content: "";
        display: inline-block;
        width: 18px;
        height: 18px;
        background-image: url("@/assets/images2/denru1.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        position: absolute;
        left: -23px;
        top: 50%;
        transform: translateY(-50%);
      }
      div:nth-child(1):hover::before {
        background-image: url("@/assets/images2/denru2.png");
      }
      div:nth-child(2n-1):hover {
        color: #ff5756;
      }
    }
    .headerCenter {
      display: flex;
      align-items: center;
      .videoLogoText{
          
          .onea{
            display: block;
          }
          .ltImg{
           img{
            width:270px;
            height: 46px;
           }
          }
          .ltText{
            display: flex;
            aling-items: center;
            color:#ffffff;
            background:#d4141a;
            padding: 6px 10px;
            border-radius: 5px;
            cursor: pointer;
            img{
              width:20px;
              height:20px;
              margin-right:10px
              }
            
          }
        }
      .heraderNav {
        display: flex;
        align-items: center;
        color: #000;
        // position: relative;

        .div {
          height: 36px;
          line-height: 36px;
          margin: 0 16px;
          text-align: center;
          cursor: pointer;
        }
        .div:not(:last-child):hover {
          border-radius: 5px;
          background-color: #fe4b4c;
        }
        .div:not(:last-child):hover a {
          color: #fff;
        }
        .div:last-child {
          a {
            color: #ff5756;
            text-decoration: none;
          }
          &:hover::after {
            content: "";
            display: block;
            width: 100%;
            height: 120px;
            background-image: url("@/assets/images/apkIcon.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            margin-top: 10px;
          }
        }
        a {
          padding: 0 18px;
        }
        .active {
          border-radius: 5px;
          background-color: #fe4b4c;
          a {
            color: #fff;
          }
        }
      }
      a {
        color: #000;
        text-decoration: none;
      }

      .imglogo {
        width: 160px;
        height: 49px;
        margin-right: 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.dialogVisibleLogin {
  margin-top: -20px;
  padding: 0 30px;
  position: relative;
  .imgFindx {
    width: 30px;
    height: 30px;
    position: absolute;
    left: -20px;
    top: -40px;
  }
  .codeText {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    margin-top: 15px;
    @include ElementInputBotton;
  }
  .userName {
    border-bottom: 1px solid #e9e9e9;
    margin-top: 30px;
    @include ElementInputBotton;
  }
  .phoneCode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    margin-top: 30px;
    @include ElementInputBotton;
    .code {
      width: 71px;
      text-align: center;
      cursor: pointer;
      flex-shrink: 0;
    }
  }
  .btnLogin {
    width: 100%;
    padding: 30px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div:nth-child(1) {
      cursor: pointer;
      text-decoration: underline;
    }
    .submit {
      height: 36px;
      width: 106px;
    }
  }
  .checkboxText {
    margin: 10px 0 30px;
    display: flex;
    justify-content: space-between;
    :deep(.el-form-item__content) {
      justify-content: space-between;
    }
    .p {
      padding-top: 2px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .nameInput2 {
    border-bottom: 1px solid #e9e9e9;
    margin-top: 30px;
    @include ElementInputBotton;
  }
  .nameInput {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
    position: relative;
    .country-code-list {
      // display: none;
      position: absolute;
      top: 34px;
      left: 0;
      width: 100%;
      height: 205px;
      z-index: 103;
      background: #fff;
      overflow-y: auto;
      box-shadow: 0 3px 10px 0 rgba(43, 58, 73, 0.1);
      ul {
        font-size: 14px;
        cursor: pointer;
        .country-code-title {
          cursor: default;
          background: #fff;
        }
        li {
          height: 34px;
          line-height: 34px;
          padding: 0 20px;
          .left {
            float: left;
          }
          .right {
            float: right;
          }
        }
        .country-code-item:hover {
          background: #f0f0f0;
        }
      }
    }
    .name {
      flex-shrink: 0;
      cursor: pointer;
    }
    .name::after {
      content: "";
      display: inline-block;
      width: 1px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid #ffb600;
      margin-left: 1px;
      margin-right: 8px;
      vertical-align: middle;
    }
    :deep(.el-form-item) {
      width: 100%;
      margin-bottom: 0;
    }
  }
  .loginTitle {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 26px;
  }
  :deep(.el-input__wrapper) {
    box-shadow: none;
  }
  :deep(.el-message) {
    z-index: 99999;
  }
  :deep(.el-input__inner) {
    font-size: 14px;
  }
}
</style>