<template>
  <div class="homePage">
    <div
      class="homeTop"
      :style="{ 'background-image': 'url(' + homeTopbg + ')' }"
    >
      <div class="topVideo">
     <div class="videoLeft">
             <iframe
            :src="iframeUrl"
            frameborder="0"
            style="width: 100%; height: 100%"
            allowfullscreen="true"
          ></iframe>
          <!-- <video
              id="player-container-id2"
              preload="auto"
              v-if="iframeUrl != ''"
              style="width: 100%; height: 100%"
              @play="onPlay" @pause="onPause"
            >
          </video>
          <div
              class="iconZanting"
              v-if="iconZantingBool && iframeUrl != ''"
            ></div> -->

          <div
            class="videoEnter"
            v-if="liveingAnchor.length != 0"
            @click="skipRouteInfo"
          >
            进入直播间 
          </div>
          <div class="videofixedset">
            <div class="videoAdvertise" v-if="logomsgObj.video_msg && iframeType == 'video'">
           {{ logomsgObj.video_msg }}
          </div>
          </div>
          <img :src="logomsgObj.video_logo" alt="" class="videoLogo" v-if="logomsgObj.video_logo && iframeType == 'video'" >
           <!-- -->
           <!-- <div class="videoLogoText" v-if="JSON.stringify(userInfoAds) != '{}'">
            <a :href="userInfoAds.ad_url || '#'" target="block" style="text-decoration: none;">
              <div class="ltText" v-if="userInfoAds.ad_text && !userInfoAds.ad_img">
              <img :src="userInfoAds.ad_icon" alt="" v-if="userInfoAds.ad_icon">
              <span>{{userInfoAds.ad_text}}</span>
            </div>
            <div class="ltImg" v-if="userInfoAds.ad_img">
              <img :src="userInfoAds.ad_img" alt="">
            </div>
            </a>
          </div> -->
        </div>
        <div class="videoRight">
          <div
            class="imgbox"
            :class="iframeNav == index ? 'imgActive' : ''"
            v-for="(item, index) in liveingAnchor"
            :key="index"
            @click="wayiframeNav(index)"
          >
            <img :src="item.fm" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- 推荐数据列表 -->
      <!-- <div class="project">
        <div class="project-swiper">
          <swiper
            :slidesPerView="5"
            :spaceBetween="10"
            :loop="true"
            :centeredSlides="false"
            :navigation="navigation"
            :modules="modules"
            class="mySwiper"
          >
            <swiper-slide v-for="(item, index) in 16" :key="index">
              <div class="srcorllbarBox">
                <div class="boxtop">
                  <div>
                    <img
                      src="https://sta.yyres.tv/file/common/20210503/fca5954ec22137ad05325506d6645592"
                      alt=""
                    />
                    <span> 午后了球{{ index }}</span>
                  </div>
                  <div>今天 14:00</div>
                </div>
                <div class="boxData">
                  <div class="left">
                    <p>
                      <img
                        src="https://sta.yyres.tv/file/common/20210503/fca5954ec22137ad05325506d6645592"
                        alt=""
                      />
                      <span>武侯了球</span>
                    </p>
                    <p>
                      <img
                        src="https://sta.yyres.tv/file/common/20210503/fca5954ec22137ad05325506d6645592"
                        alt=""
                      />
                      <span>武侯了球</span>
                    </p>
                  </div>
                  <div class="righte">预约</div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="button-prev" @click.stop="prevEl">
            <el-icon :size="40" color="#979797"><ArrowLeft /></el-icon>
          </div>
          <div class="button-next" @click.stop="nextEl">
            <el-icon :size="40" color="#979797"><ArrowRight /></el-icon>
          </div>
        </div>
      </div> -->

      <!-- 正在热播数据列表  -->
      <div class="homeList">
        <div class="listTitle">
          <img src="@/assets/images/zhengzairib.png" class="imgone" />
          <span @click="skipMore"> 查看更多 </span>
        </div>
        <div class="dataList">
          <div
            class="li"
            v-for="(item, index) in infoList"
            :key="index"
            @click="skipRoute(item.id, item.type , item.fire)"
          >
            <div class="liImage">
              <img :src="item.live_cover" alt="" class="imgbg" />
              <h5 class="bottom-title">
                <span class="name">{{ item.fm_zb }}</span>
                <span class="num">
                  <img
                    src="https://www.xm888.app/Public/fm/icon-hot-white.png"
                    srcset=""
                  />
                  <span>{{ item.fire }}</span>
                </span>
              </h5>
              <img
                src="@/assets/images/zanting.png"
                alt=""
                class="zantingImg"
              />
            </div>

            <div class="liText">
              <p>{{ item.liveroom_title }}</p>
              <p>{{ item.league }}</p>

              <!-- <p>
                <span>
                  <img
                    class="head"
                    src="https://sta.yyres.tv/file/head/20230502/f515dbfa0ac4e5c1848a45ecb39f9fc3.jpg"
                  />
                  单名
                </span>
                <span>14.16w</span>
              </p> -->
            </div>
            <div class="top-tag float-clear">
              <span class="com">
                <i>推荐</i>
              </span>
              <div class="living" v-if="item.tuijian != ''">
                <img src="https://www.xm888.app/Public/fm/living.gif" />
                <span>Live</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 热门主播 -->
      <div class="hotZhubo">
        <div class="topImage">
          <img src="@/assets/images/hot.png" alt="" />
        </div>
        <div class="hotSwiper">
          <swiper
            :slidesPerView="8"
            :spaceBetween="10"
            :loop="false"
            :centeredSlides="false"
            :navigation="navigation2"
            :modules="modules"
            class="mySwiper"
          >
            <swiper-slide v-for="(item, index) in anchorsList" :key="index">
              <div class="hotList">
                <div class="hotBox">
                  <img :src="item.headimg" alt="" />
                  <p>{{ item.anchor_nickname }}</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="button-prev2" @click.stop="prevEl">
            <!-- <img src="@/assets/logo.png" alt="" /> -->
            <el-icon :size="40" color="#979797"><ArrowLeft /></el-icon>
          </div>
          <div class="button-next2" @click.stop="nextEl">
            <!-- <img src="@/assets/logo.png" alt="" /> -->
            <el-icon :size="40" color="#979797"><ArrowRight /></el-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="suspend" v-if="scrollYBool">
      <div class="bostop" @click="scrollToTop">
        <img src="@/assets/images/abs01.png" alt="" />
        <p>返回顶部</p>
      </div>
      <div class="bostop">
        <router-link to="/appinstall">
          <img src="@/assets/images/abs02.png" alt="" class="img2" />
          <p>下载APP</p>
        </router-link>
      </div>
      <!-- <div class="bostop">
        <img src="@/assets/images/abs03.png" alt="" class="img2" />
        <p>意见反馈</p>
      </div> -->
    </div>
  </div>
</template>

<script >
import {
  usersAttenAnchorsShow,
  popularMatchsForNows,
  nowLiveingAnchor,
  getVideoLogoMsg,
} from "@/axios/api";
// import TCPlayer from 'tcplayer.js';
// import 'tcplayer.js/dist/tcplayer.min.css';
import { onMounted, ref, reactive, toRefs, watch, nextTick } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Navigation, Pagination, A11y } from "swiper";
import { useRouter } from "vue-router";
import "swiper/scss"; 
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { exportGetBrowserId } from "@/until/upmethods.js";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  setup() {
    const router = useRouter();
    const modules = ref([Autoplay, Pagination, Navigation, A11y]);
    const Player = ref(null);
   
    const onPlay = ()=>{
      console.log('视频已开始播放');
      state.iconZantingBool = false
      Player.value.play()
    }
    const onPause = ()=>{
      console.log('视频已暂停');
      Player.value.pause()
      state.iconZantingBool = true
    }


    // 跳转直播
    const skipRoute = (id, type ,fire) => {
      router.push({
        path: "/liveInfo",
        query: { id, type ,fire },
      });
    };
    const skipRouteInfo = () => {
      // 获取zb_id和type
      let id = state.liveingAnchor[state.iframeNav].zb_id;
      let type = state.liveingAnchor[state.iframeNav].type;
      // 跳转到/liveInfo路由，并传递id和type参数
      router.push({
        path: "/liveInfo",
        query: { id, type },
      });
    };
    //查看更多
    const skipMore = () => {
      router.push({
        path: "/schedule",
      });
    };
    //返回顶部
    const scrollToTop = () => {
      // 滚动到页面顶部
      window.scrollTo({
        top: 0, // 设置滚动到的距离为0，即顶部
        behavior: "smooth", // 缓慢滚动
      });
    };
    const state = reactive({
      iconZantingBool:true,
      userInfoAds:{},
      navigation2: {
        nextEl: ".button-next2",
        prevEl: ".button-prev2",
      },
      navigation: {
        nextEl: ".button-next",
        prevEl: ".button-prev",
      },

      homeTopbg:
        "https://home.xm888.app/Public/icon/backgroudnew.png",
      anchorsList: [], //热门主播列表
      infoList: [], //正在热播列表
      liveingAnchor: [], // 顶部正在热播
      iframeUrl: "",
      iframeNav: 0,
      scrollY: 0,
      scrollYBool: false,
      logomsgObj:{},
      iframeType:'',
      m3u81:'',
      cookieID:''
    });
    //获取系统消息 
    const wayGetVideoLogoMsg = ()=>{
      getVideoLogoMsg()
      .then(res=>{
        state.logomsgObj = res.data
        if(res.data.ads.ads.length > 0){
          state.userInfoAds = res.data.ads.ads[0]
        }
      }).catch(err=>{})
    }
    //监听页面滚动距离
    window.addEventListener("scroll", function () {
      state.scrollY = window.scrollY;
    });
    //监听当前页面距离顶部的高度
    watch(
      () => state.scrollY,
      async (newQuestion, oldQuestion) => {
        if (newQuestion >= 666) {
          state.scrollYBool = true;
        } else {
          if (state.scrollYBool == false) return;
          state.scrollYBool = false;
        }
      }
    );
    //请求热门主播
    const wayusersAttenAnchorsShow = () => {
      usersAttenAnchorsShow({ uid: 0,cid:state.cookieID }).then((res) => {
        state.anchorsList = res.data;
      }).catch(err=>{})
    };
    //请求正在热播
    const waypopularMatchsForNows = () => {
      popularMatchsForNows({ uid: 0, type: 1 }).then((res) => {
        state.infoList = res.data;
      }).catch(err=>{})
    };
    // 请求顶部推荐直播
    const waynowLiveingAnchor = () => {
      nowLiveingAnchor({ uid: 0 }).then((res) => {
        state.liveingAnchor = res.data;
        if (res.data[0]){
          state.iframeUrl = res.data[0].pull_address;
          state.m3u81 = res.data[0].m3u81;
          state.iframeType =res.data[0].type
          setTimeout(() => {
            // Player.value.src(res.data[0].m3u81)
          }, 1000);
        } 
      }).catch(err=>{})
    };
    //切换顶部直播iframe
    const wayiframeNav = (i) => {
      try{
        state.iframeNav = i;
        state.iframeUrl = state.liveingAnchor[i].pull_address;
        state.m3u81 = state.liveingAnchor[i].m3u81;
        state.iframeType = state.liveingAnchor[i].type
        // Player.value.src(state.liveingAnchor[i].m3u81)
      }catch(err){}
    }
       // 腾讯云直播 
    const tcplayerfunction = ()=>{
      try{
        setTimeout(() => {
          Player.value = TCPlayer('player-container-id2', {
          licenseUrl: 'https://license.vod-pro.com/license/v2/1324791583_1/v_cube.license', // license 地址，参考准备工作部分，在视立方控制台申请 license 后可获得 licenseUrl
          bigPlayButton:false,
        })
        }, 300);
      
      }catch(err){
        console.log('执行了2',err)
      }
    }
 
    onMounted(() => {
      // tcplayerfunction()
      state.cookieID = exportGetBrowserId()
      waynowLiveingAnchor();
      waypopularMatchsForNows();
      wayusersAttenAnchorsShow();
      wayGetVideoLogoMsg()
    });

    return {
      ...toRefs(state),
      skipRoute,
      skipMore,
      scrollToTop,
      wayusersAttenAnchorsShow,
      waypopularMatchsForNows,
      waynowLiveingAnchor,
      wayiframeNav,
      skipRouteInfo,
      modules,
      onPlay,
      onPause
    };
  },
};
</script>

<style lang="scss" scoped>
.homePage {
  width: 100%;
  a {
    color: #666;
    text-decoration: none;
  }
  .suspend {
    position: fixed;
    right: 30px;
    bottom: 120px;
    width: 70px;
    .bostop {
      display: inline-block;
      background: #ffffff;
      border-radius: 5px 5px 5px 5px;
      opacity: 1;
      font-size: 12px;
      text-align: center;
      color: #666666;
      margin-bottom: 10px;
      background: #fff;
      border: 1px solid #dadada;
      padding: 10px;
      cursor: pointer;
      img {
        width: 12px;
        height: 8px;
        margin-bottom: 8px;
      }
      .img2 {
        width: 20px;
        height: 20px;
      }
    }
  }
  .container {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;

    .project {
      width: 100%;
      margin: auto;
      padding: 40px 0 20px;
      &-swiper {
        position: relative;
        margin: auto;
        swiper {
          width: 100%;
          swiper-slide {
            height: 100%;
            box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.16);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #e4e5ea;
            //width: 100%;
            background: #f9fafe;
          }
        }
        .button-prev {
          position: absolute;
          top: 50%;
          left: -45px;
          transform: translateY(-50%);
          cursor: pointer;
        }
        .button-next {
          position: absolute;
          top: 50%;
          right: -45px;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }

      .srcorllbarBox {
        flex-shrink: 0;
        width: 240px;
        height: 100%;
        background: #fff;
        box-sizing: border-box;
        padding: 16px 16px 20px;
        .boxData {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            p {
              img {
                width: 24px;
                height: 24px;
                vertical-align: middle;
              }
              span {
                display: inline-block;
                width: 112px;
                margin: 0 0 0 6px;
                font-size: 14px;
                font-weight: 400;
                height: 24px;
                line-height: 24px;
              }
            }
          }
          .righte {
            cursor: pointer;
            padding: 4px 10px;
            font-size: 14px;
            color: #fff;
            background: #fe4b4c;
          }
        }
        .boxtop {
          color: #777;
          font-size: 12px;
          margin: 0 0 20px;
          height: 17px;
          line-height: 17px;
          div:nth-child(1) {
            float: left;
            img {
              width: 12px;
              height: 12px;
              vertical-align: middle;
            }
          }
          div:nth-child(2) {
            float: right;
          }
        }
      }
    }

    .swiper-content {
      text-align: center;
      height: 94px;
      margin: 40px 0;
      border-right: 1px solid #e4e5ea;

      &-text {
        margin: 11px auto 0;
        width: 127px;
        height: 25px;
        font-size: 16px;
        //font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #2f353b;
        line-height: 25px;
        // -webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;
      }
    }

    .hotZhubo {
      width: 100%;
      margin-top: 60px;
      .hotSwiper {
        position: relative;
        .hotList {
          width: 1100px;
          display: flex;
          .hotBox {
            width: 138px;
            padding: 0 20px;
            text-align: center;
            flex-shrink: 0;
            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              margin-bottom: 5px;
            }
          }
        }
        .button-prev2 {
          position: absolute;
          top: 50%;
          left: -45px;
          transform: translateY(-50%);
          cursor: pointer;
        }
        .button-next2 {
          position: absolute;
          top: 50%;
          right: -45px;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
      .topImage {
        img {
          width: 100%;
        }
      }
    }

    .homeList {
      margin-top: 15px;
      .dataList {
        overflow: hidden;
        .li:nth-child(4n) {
          margin-right: 0;
        }
        .li {
          float: left;
          width: 285px;
          height: 220px;
          border-radius: 5px;
          overflow: hidden;
          background: #fff;
          margin: 0 20px 20px 0;
          cursor: pointer;
          position: relative;

          .top-tag {
            position: absolute;
            width: 100%;
            top: 10px;
            zoom: 1;
            .living {
              float: right;
              height: 18px;
              padding: 0 4px;
              margin-right: 8px;
              line-height: 18px;
              font-size: 0;
              background: #fa3434;
              border-radius: 2px;
              img {
                width: 10px;
                height: 10px;
                margin-right: 4px;
                vertical-align: middle;
              }
              span {
                position: static;
                font-size: 12px;
                color: #fff;
                border-radius: 0;
                border-right: 0;
                vertical-align: top;
              }
            }
            .com {
              border-top-color: #218aff;
              float: left;
              padding-right: 14px;
              border-top: 22px solid #218aff;
              border-right: 6px solid transparent;
              border-radius: 0 3px 9px 0;
              color: #fff;
              i {
                position: relative;
                top: -21px;
                left: 8px;
                font-size: 14px;
                font-style: normal;
                text-align: center;
              }
            }
          }
          .liText {
            p:nth-child(1) {
              height: 34px;
              padding: 10px 12px;
              line-height: 20px;
              font-weight: 400;
              font-size: 14px;
              border-bottom-left-radius: 6px;
              border-bottom-right-radius: 6px;
              color: #000;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            p:nth-child(2) {
              font-size: 13px;
              color: #777777;
              padding: 0 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            // p:nth-child(2) {
            //   padding: 1px 12px;
            //   color: #777;
            //   line-height: 25px;
            //   font-weight: 400;

            //   span:nth-child(1) {
            //     max-width: 70%;
            //     overflow: hidden;
            //     font-size: 12px;
            //     float: left;
            //     text-overflow: ellipsis;
            //     white-space: nowrap;
            //     img {
            //       width: 20px;
            //       height: 22px;
            //       border-radius: 50%;
            //       float: left;
            //       padding-top: 2px;
            //       margin-right: 4px;
            //     }
            //   }
            //   span:nth-child(2) {
            //     background: url(https://sta.xuean.xyz/web/assets/yy/img/icon-hot-gray.png)
            //       no-repeat 0;
            //     padding-left: 15px;
            //     background-size: 12px auto;
            //     font-size: 12px;
            //     float: right;
            //   }
            // }
          }
          .liImage {
            width: 100%;
            height: 160px;
            position: relative;
            .zantingImg {
              // display: none;
              width: 44px;
              height: 44px;
              scale: (0);
              position: absolute;
              top: 50%;
              left: 50%;
              transition: all 0.2s;
              margin: -25px 0 0 -25px;
            }

            .bottom-title {
              font-weight: 400;
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 28px;
              line-height: 20px;
              font-size: 14px;
              color: #fff;
              padding: 0 14px 8px;
              letter-spacing: 1px;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0,
                rgba(0, 0, 0, 0.8) 100%
              );
              .name {
                display: inline-block;
                width: 12em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .num {
                float: right;

                img {
                  width: 14px;
                  float: left;
                  padding-top: 3px;
                  padding-right: 2px;
                  object-fit: cover;
                }
              }
            }
            .imgbg {
              width: 100%;
              height: 100%;
            }
          }
        }
        .li:hover .zantingImg {
          // display: block !important;
          scale: (1);
        }

        .placeholder {
          margin-left: auto;
          margin-right: auto;
        }
      }
      .listTitle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #777;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;

        .imgone {
          width: 180px;
        }
        span {
          margin-top: 12px;
          color: #777;
          font-size: 14px;
          font-weight: 400;
          background: url("@/assets/images/icon-more.png") no-repeat right;
          padding-right: 13px;
          background-position: right center;
          background-size: 10px auto;
          cursor: pointer;
          text-decoration: none;
          float: right;
        }
      }
    }
  }

  .homeTop {
    width: 100%;
    height: 567px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .topVideo {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      background: #0e0e18;
      display: flex;
      .videoRight {
        .imgbox {
          width: 167px;
          height: 102px;
          margin: 10px;
          position: relative;
          cursor: pointer;
          &:hover {
            border: 1px solid #fe4b4c;
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: -7px;
              width: 0;
              height: 0;
              transform: translateY(-50%);
              border-top: 6px solid transparent;
              border-bottom: 6px solid transparent;
              border-right: 6px solid #fe4b4c;
            }
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .imgActive {
          border: 1px solid #fe4b4c;
        }
        .imgActive::before {
          content: "";
          position: absolute;
          top: 50%;
          left: -7px;
          width: 0;
          height: 0;
          transform: translateY(-50%);
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-right: 6px solid #fe4b4c;
        }
      }
      .videoLeft {
        width: 1015px;
        height: 100%;
        position: relative;
        overflow: hidden; 
        .videoLogoText{
          position: absolute;
          left:0px;
          bottom:42px;
          z-index: 9;
          .ltImg{
           img{
            width:160px;
            height: 50px;
           }
          }
          .ltText{
            display: flex;
            aling-items: center;
            color:#ffffff;
            background:#d4141a;
            padding: 6px 10px;
            border-radius: 5px;
            cursor: pointer;
            img{
              width:20px;
              height:20px;
              margin-right:10px
              }
            
          }
        }
        .videoLogo{
          position: absolute;
          right: 30px;
          top: 40px;
          width: 180px;
          height: 50px;
          z-index: 99;

        }
        .videofixedset{
          position: absolute;
          left: 1px;
          bottom: 20px;
          width: 100%;
        }
        .videoAdvertise{
          color: red;
          display: inline-block;
          white-space: nowrap;
          z-index: 99;
          font-size: 22px;
          font-weight: bold;
          pointer-events:none;
          animation: slide 70s linear infinite;
        }
        @keyframes slide{
          0%{
            // transform: translateX(100%);
            text-indent: 1013px;
          }
          100%{
            // transform: translateX(-100%);
            text-indent: -100%;
          }
        }
        &:hover .videoEnter {
          color: #fff;
          background: #fe4b4c;
          opacity: 1;
        }
        .iconZanting {
            position: absolute;
            width: 120px;
            height: 120px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url(https://www.xm888.app/Public/icon/play.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            // z-index: 99;
            pointer-events:none
          }
        .videoEnter {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          height: 60px;
          padding: 0 25px;
          line-height: 60px;
          background: #000;
          border-radius: 6px;
          opacity: 0.5;
          border: 1px solid #fe4b4c;
          font-size: 20px;
          font-weight: 600;
          color: #fe4b4c;
          cursor: pointer;
          -webkit-transition: 0.5s;
          -moz-transition: 0.5s;
          transition: 0.5s;
        }
      }
    }
  }
}
</style>